h2 {
  font-weight: 400;
  font-size: 2.2em;
}

h3 {
  font-size: 1.65em;
  font-weight: 400;
  color: #737373;
}

h4 {
  font-size: 1.4em;
  font-weight: 400;
}

h1, h2, h3, h4, h5, h6 {
  margin-bottom: 15px;
  color: #2d2d2d;
}