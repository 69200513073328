.patient-form {
  &__form {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  &__item-form {
    width: 50%;

    &--max-width {
      width: 100%;
    }

    & > .ant-form-item-label {
      width: 110px;
    }
  }
}