@import "src/assets/scss/mixins/mixins";
@import "src/assets/scss/functions/functions";

.loader-background {
  @include d-flex-fully-centered();
  width: 100%;
  height: 100vh;
  background-color: mediumseagreen;
  position: fixed;
  z-index: 5138008;
  top: 0;
  left: 0;

  &__loader {
    @include equalHeightWidth(35px, true);
    border-width: 5px !important;
    border-top-color: orange !important;
  }

  &__text {
    margin-top: 3px; // pixel-accurate alignment
    margin-left: 15px;
    font-size: 1.4em;
    font-weight: 500;
    color: lightgray;
  }
}
