.welcome {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  $margin: 50px;

  &__subtitle {
    margin-bottom: unset;
  }

  h2, &__subtitle {
    text-align: center;
  }

  &__card {
    margin: $margin 0;
  }

  &__information {
    color: #666;

    span {
      font-weight: 500;
    }
  }

  &__start-btn {
    margin-bottom: $margin;
  }
}