@import "src/assets/scss/functions/functions";
@import "src/config/theme/theme";

.stepper-progress {
  .ant-steps-progress-icon { // Fix an Antd position problem
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .ant-steps-item-finish .ant-steps-item-icon {
    border-color: get($theme, ("palette", "primary", "light")) !important;
  }

  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    background: get($theme, ("palette", "primary", "light")) !important;
    border-color: get($theme, ("palette", "primary", "light")) !important;
  }

  .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
    stroke: get($theme, ("palette", "primary", "light")) !important;
  }

  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
    background-color: get($theme, ("palette", "primary", "light")) !important;
  }
}