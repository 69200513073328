$palette: (
  primary: (
    light: #5e83ad,
    main: #3b536e,
    dark: #263546,
  ),
  secondary: (
    main: #ffffff,
  ),
  info: (
    main: #666,
  ),
  error: (
    main: #f44336,
  ),
  background: (
    default: #fafbfd,
  ),
  border: (
    default: #ddd,
  ),
);
