.view-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__title {
    margin: 70px 0 40px 0;
  }

  &__next-btn {
    margin-top: 50px;
  }
}