@import "src/assets/scss/functions/functions";
@import "src/config/theme/theme";

.headphones {
  &__card-type {
    & > .ant-card-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .ant-radio-button-wrapper-checked {
      background: get($theme, ("palette", "primary", "light")) !important;
      border-color: get($theme, ("palette", "primary", "light")) !important;
    }

    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
      background-color: get($theme, ("palette", "primary", "light")) !important;
    }
  }

  &__card-title {
    margin-bottom: 10px;
  }
}