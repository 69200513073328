.age-range {
  &__age {
    &-pad {
      width: calc((140px * 3) + (20px * 2));
    }

    &-btn {
      width: 140px;
      text-align: center;
      border-left-width: 1px !important;

      &:not(:nth-last-child(-n + 3)) {
        margin-bottom: 20px !important;
      }

      &:not(:last-child):not(:nth-child(3n)) {
        margin-right: 20px;
      }

      &.ant-radio-button-wrapper-checked {
        background: lightseagreen !important;
        border-color: lightseagreen !important;

        &::before {
          background-color: lightseagreen !important;
        }
      }
    }
  }
}
