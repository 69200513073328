@import "src/assets/scss/mixins/mixins";
@import "src/assets/scss/functions/functions";

.loader {
  @include equalHeightWidth(42px); // size
  border: 16px solid lightgray; // size and color of background
  border-top: 16px solid mediumseagreen; // size and color of a portion (1/4)
  border-radius: 50%;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
