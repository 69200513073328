@import "src/assets/scss/functions/functions";
@import "src/config/theme/theme";

.header {
  height: 100px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 30px !important;
  background: transparent !important;

  &__btn {
    background: get($theme, ("palette", "primary", "light")) !important;
    border-color: get($theme, ("palette", "primary", "light")) !important;
  }
}