.content {
  min-height: unset !important; // override antd

  &__row {
    height: 100%;
    padding-top: 50px;
  }

  &__col {
    padding: 0 15px;
  }
}