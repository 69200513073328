.setup-instructions {
  &__col {
    display: flex !important;
    flex-direction: column;
    align-items: center;
  }

  &__text {
    text-align: center;
  }
}