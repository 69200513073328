@import "src/assets/scss/functions/functions";
@import "src/config/theme/theme";

.player {
  .ant-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    background: get($theme, ("palette", "primary", "light")) !important;
    border-color: get($theme, ("palette", "primary", "light")) !important;

    &.ant-btn-sm {
      width: 50px;
      height: 50px;
    }

    &.ant-btn-lg {
      width: 80px;
      height: 80px;
    }

    .anticon, svg {
      width: 2em;
      height: 2em;
    }
  }
}