.recontact {
  &__yes-btn {
    &,
    &:hover {
      background: lightseagreen !important;
      border: lightseagreen !important;
    }
  }

  &__no-btn {
    &,
    &:hover {
      background: lightcoral !important;
      border: lightcoral !important;
    }
  }
}